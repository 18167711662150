<template>
  <div>
    <vx-card>
      <h2
        class="ml-2 font-semibold mb-2"
      >{{$t(resources.BinaryTree.i18n) || resources.BinaryTree.name}}</h2>

      <div class="vx-row mt-2">
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="$t(resources.DistributorID.i18n) || resources.DistributorID.name"
            v-model="itaCodeForm"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="$t(resources.UntilLevel.i18n) || resources.UntilLevel.name"
            v-model="untilLevel"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-5">
          <vs-button
            color="primary"
            type="filled"
            :disabled="!validateForm"
            @click="search()"
          >{{$t(resources.Search.i18n) || resources.Search.name}}</vs-button>
        </div>
      </div>

      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="success"
          ></vs-icon>
          {{$t(resources.Associated.i18n) || resources.Associated.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(173, 170, 214)"
          ></vs-icon>
          {{$t(resources.Silver.i18n) || resources.Silver.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(57, 208, 253)"
          ></vs-icon>
          {{$t(resources.Gold.i18n) || resources.Gold.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(27, 0, 246)"
          ></vs-icon>
          {{$t(resources.Platinum.i18n) || resources.Platinum.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(255, 252, 35)"
          ></vs-icon>
          {{$t(resources.Diamond.i18n) || resources.Diamond.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(255, 155, 8)"
          ></vs-icon>
          {{$t(resources.DoubleDiamond.i18n) || resources.DoubleDiamond.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(238, 33, 236)"
          ></vs-icon>
          {{$t(resources.TripleDiamond.i18n) || resources.TripleDiamond.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="2"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(238, 33, 33)"
          ></vs-icon>
          {{$t(resources.Crown.i18n) || resources.Crown.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="3"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="rgb(0, 255, 46 )"
          ></vs-icon>
          {{$t(resources.CrownAmbassador.i18n) || resources.CrownAmbassador.name}}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="3"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon icon-pack="material-icons" icon="fiber_manual_record" size="small" color="dark"></vs-icon>
          {{$t(resources.Unclassified.i18n) || resources.Unclassified.name}} - {{$t(resources.Terminated.i18n) || resources.Terminated.name}}
        </vs-col>
      </vs-row>

      <vs-row>
        <tree :tree-data="tree"></tree>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import ranks from "@/assets/resources/ranks.js";
import Tree from "./tree";
import alerts from "@/i18n/alerts.js";

export default {
  components: {
    Tree
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      itaCodeForm: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      fromLevel: 0,
      untilLevel: 4,
      ranks: ranks,
      tree: { status: "" },
      isDownline: 0
    };
  },
  created: function() {
    this.treeReport();
  },
  computed: {
    validateForm() {
      if (this.untilLevel > 10) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgNotAllow10Rows"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle"
        });
      }

      return !this.errors.any() && this.untilLevel <= 10;
    }
  },
  methods: {
    async isInDownline() {
      let response = true;
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "isInDownline",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          target: this.itaCodeForm
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          response = result.data;
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      return response;
    },

    search() {
      this.isInDownline().then(res => {
        if (res == "false") {
          this.isDownline = 0;
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgDistNotExist"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle"
          });
        } else {
          this.treeReport();
        }
      });
    },

    async treeReport() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "treeReport",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCodeForm,
          qstr: "level >= " + this.fromLevel + "&level<=" + this.untilLevel,
          returnfields:
            "dist-id, name,bus-ctr,sponsor, sponsor-side, level, status, end-rank,paid-rank,vol3,vol1",
          limit: 1000
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.setTree(result.data.DATA);
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    setTree(data) {
      let json = "[";
      for (var i = 0; i < data.DIST_ID.length; i++) {
        let endRank = this.ranks.find(ranks => ranks.value == data.END_RANK[i])
          .i18n;

        json = json.concat(
          '{"dist_id":"' +
            data.DIST_ID[i] +
            '","name":"' +
            data.NAME[i] +
            '","bus_ctr":"' +
            data.BUS_CTR[i] +
            '", "level":"' +
            data.LEVEL[i] +
            '","sponsor":"' +
            data.SPONSOR[i] +
            '","sponsor_side":"' +
            data.SPONSOR_SIDE[i] +
            '","status":"' +
            data.STATUS[i] +
            '","endRank":"' +
            this.$i18n.t(endRank) +
            '","endRankId":"' +
            data.END_RANK[i] +
            '","vol3":"' +
            data.VOL3[i] +
            '","vol1":"' +
            data.VOL1[i] +
            '"},'
        );
      }
      json = json.substring(0, json.length - 1) + "]";
      let obj = JSON.parse(json);
      this.jsonTree = obj;
      this.buildJson(obj);
    },

    buildJson(data) {
      const toLevel = parseInt(this.untilLevel);
      try {
        var nodesPerLevel = [];

        data = data.filter(data => data.status != "C");
        let level = -1;
        data.forEach(function(item) {
          level++;
          let lvl = parseInt(item.level);

          var node = {
            id: level,
            title: item.status != "T" ? item.dist_id : "",
            dist: item.dist_id,
            level: item.level,
            name: item.name,
            endRank: item.endRank,
            endRankId: item.endRankId,
            vol3: item.vol3,
            vol1: item.vol1,
            bus_ctr: item.bus_ctr,
            status: item.status,
            side: item.sponsor_side,
            expanded: true,
            children: []
          };

          if (level < toLevel - 1) {
            if (lvl == 0) {
              nodesPerLevel.push(node);
              nodesPerLevel[0] = node;
            } else {
              nodesPerLevel[level - 1].children.push(node);
              nodesPerLevel[level] = node;
            }
          } else if (item.sponsor_side == "R") {
            if (nodesPerLevel[lvl - 1].dist == item.sponsor) {
              nodesPerLevel[lvl - 1].children.push(node);
              nodesPerLevel[lvl] = node;
            }
          } else if (item.sponsor_side == "L") {
            if (nodesPerLevel[lvl - 1].dist == item.sponsor) {
              nodesPerLevel[lvl - 1].children.push(node);
              nodesPerLevel[lvl] = node;
            }
          }
          if (data.length == 1) return;
        });
        let json = JSON.stringify(nodesPerLevel[0]);
        this.tree = JSON.parse(json);
      } catch (error) {
        this.$vs.notify({
          time: 6000,
          title: this.$i18n.t("Alert"),
          text:
            this.$i18n.t("MsgNotBinaryTree") +
            ".<br/><br/>[" +
            error.message +
            "]",
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle"
        });
      }
    }
  }
};
</script>
<style>
@import "../../assets/css/tree.css";
</style>
